$(function() {

  var alert = $('.alert-box'),
      alertH = alert.outerHeight();

  function dismissAlert() {
    alert.animate({ marginTop: -alertH, opacity: 0 }, 2000, function() {
      alert.hide();
    });
  }

  if (alert.length && alert.hasClass('success')) {
    setTimeout(dismissAlert, 5000);
  }
  if (alert.length && alert.hasClass('error')) {
    setTimeout(dismissAlert, 10000);
  }

});